import * as React from "react"
import { Group } from '@mantine/core'
import { useTimer } from 'use-timer';

import FullLogo from "../../../images/logos/royal-palm-logo-main"
import AzulManagementLogo from "../../../images/logos/azulManagementLogo"
import BlueMarkLogo from "../../../images/logos/blueMarkLogo"
import TrueBlueLogo from "../../../images/logos/trueBlueLogo"
import RoyalPalmLogo from "../../../images/logos/royalPalmLogo"



function ComingSoon() {

    const { time } = useTimer({
        initialTime: 10,                        
        autostart: true,
        timerType: 'DECREMENTAL',
    });
    
	return (

        <div className="coming-soon">
            
            <div class="index-primary">
                <FullLogo />
                <div className="primary">
                <h1>Redirecting</h1>
                </div>                       
            </div>
            
                
           

            <div class="index-secondary">

                <div className="content">
                    <h2>Welcome</h2>
                    <p>Our website is currently under construction.<br/>You will be redirected to our <br/>Azul&nbsp;Management site in {time} seconds. <a target="_blank" href="https://azulmanagement.co/realestate/" rel="noreferrer"><br />Click&nbsp;here</a> to go now.</p>
                   
          
                </div>          



        </div>
        <footer id="footer">
                <div id="max-width">
                         <div className="all-company-logos">
                                <Group spacing="xl">
                                   <RoyalPalmLogo />
                                    <BlueMarkLogo />
                                    <AzulManagementLogo />
                                    <TrueBlueLogo />     
                                    
                                </Group>
                            </div>
                    </div>
                            <div className="footer-wrapper">
                                <p>Copyright © {new Date().getFullYear()} Royal Palm Destinations. All Rights Reserved. Belize.</p>
                            </div>
                   
              </footer>
        </div>
        
        
    );
}

export default ComingSoon;
